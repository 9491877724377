import React from "react"
import 'uikit/dist/css/uikit.css'
//import UIkit from 'uikit'
import Layout from "../layouts"

import { headerParallax, howItWorks, reasonList, smallerBanner, firstBanner, secondBanner, aboutUsParallax, reviewList, lastBanner } from "../utils/homepageInformation";
import ParallaxComponent from "../components/parallax-component/parallax-component"
import ReasonList from "../components/reason-list/reason-list";
import Comparison from "../components/comparison/comparison";
import Button from '../components/button/button';
import Banner from "../components/banner/banner";
import ReviewCarousel from "../components/review-carousel/review-carousel";


const IndexPage = () => {


  return (
    <Layout SEOTitle={"Free Motor Legal | The free alternative to motor legal protection and legal expenses insurance"} SEODescription={""} footerDisclaimer={""}>

      <ParallaxComponent props={headerParallax} />
      <ReasonList props={reasonList} />
      <Comparison props={howItWorks} />
      <Button
        btnType="primary"
        label="How does it work?"
        url="/how-it-works"
        link="true"
      />

      <Banner props={smallerBanner} />
      <Banner props={firstBanner} />
      <Banner props={secondBanner} />

      <ParallaxComponent props={aboutUsParallax} />

      <ReviewCarousel props={reviewList}/>

      <Banner props={lastBanner} />



    </Layout>
  )

}

export default IndexPage