import React from 'react';
import './reason-list.scss';
import { GrCheckmark }from "react-icons/gr";


const ReasonList = ({ props }) => {
    return (
        <div className="reasons-container" id="how-it-works">
            <h2>{props.title}</h2>
            <div className="reason-list">
                <ul>
                    {
                        props.reasons.map((item, index) => {
                            return (
                                <li key={index}>
                                    <span><GrCheckmark /></span>
                                    <p>{item.reason}</p>
                                </li>
                            )
                        })
                    }
                </ul>
            </div>
            <h3>{props.subtitle}</h3>
        </div>

    )
}

export default ReasonList;