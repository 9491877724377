import React from 'react';
import { useState } from 'react';
import './review-box.scss';


const ReviewBox = ({ props }) => {

    const [isReadMoreShown, setReadMoreShown] = useState(false)
    const toggleBtn = () => {
        setReadMoreShown(prevState => !prevState)
    }

    return (

        <div className="cards">
            {
                props.author ? <h3>{props.author}</h3> : ""
            }
            {
                isReadMoreShown == false && props.review.length >= 150 ?
                    <p>{props.review.substr(0, 150) + '...'}</p>
                    :
                    <p>{props.review}</p>
            }
            {props.review.length >= 150 ? <button className="readmore-btn" onClick={() => setReadMoreShown(!isReadMoreShown)}> {isReadMoreShown ? 'Less Information ︽' : 'More Information ︾'} </button> : ""}
            
        </div>
        
    )
}

export default ReviewBox;