import React, { useState } from "react"
import "../paragraph/paragraph.scss"
import "./review-carousel.scss";
import { Link } from "gatsby"
import ReviewBox from "../review-box/review-box";


const ReviewCarousel = ({ props }) => {



    return (
        <>
            <div className="bg_color">
                <div className="carousel-container">
                    <div className="reviews-cta-card-grid">
                        <div className="uk-slider uk-slider-container" uk-slider="true">
                            <div className="uk-position-relative uk-visible-toggle slider-content" tabIndex="-1">
                                <div className="uk-slider-container uk-light">
                                    <ul className="uk-slider-items uk-child-width-1-1@xs uk-child-width-1-2@s uk-child-width-1-3@m">
                                        {
                                            props.reviewList ? props.reviewList.map((item, index) => {
                                                return (
                                                    
                                                        <li key={index} className="category-box">
                                                            <div className="uk-card uk-card-default uk-margin cta-card-carousel" uk-grid="true">
                                                                <div className="card-body-carousel">
                                                                        <ReviewBox props={item} key={index} />
                                                                </div>
                                                            </div>
                                                        </li>
                                                    
                                                )
                                            })
                                                : ""
                                        }
                                    </ul>
                                </div>


                                <div className="uk-hidden@s uk-light left-slide">
                                    <a className="uk-position-center-left uk-position-small icon-arrow" href="#" uk-slidenav-previous="true" uk-slider-item="previous" aria-label="slide-prev"></a>
                                    <a className="uk-position-center-right uk-position-small icon-arrow" href="#" uk-slidenav-next="true" uk-slider-item="next" aria-label="slide-next"></a>
                                </div>

                                <div className="uk-visible@s right-slide">
                                    <a className="uk-position-center-left-out uk-position-small icon-arrow" href="#" uk-slidenav-previous="true" uk-slider-item="previous" aria-label="slide-prev"></a>
                                    <a className="uk-position-center-right-out uk-position-small icon-arrow" href="#" uk-slidenav-next="true" uk-slider-item="next" aria-label="slide-next"></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        </>
    )
}

export default ReviewCarousel