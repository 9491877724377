import stopSign from '../assets/stop-sign.webp'
import stopSignPng from '../assets/stop-sign.png'
import first from '../assets/first.png'
import second from '../assets/second.png'
import third from '../assets/third.png'
import bannerFirstLogo from '../assets/banner1.png'
import bannerSecondLogo from '../assets/banner2.png'
import aboutWebp from '../assets/aboutWebp.webp'
import aboutPng from '../assets/aboutPng.png'

export const headerParallax = {
  banner: false,
  color: '#97e400a6',
  alignment: 'center',
  image: stopSign,
  pngImage: stopSignPng,
  title: `Stop paying for motor legal`,
  secondTitle: `protection, forever!`,
  description: `For Britain’s 30 million + motorists, the cost of motor legal protection at £30 per policy means a nationwide cost of up to £900Million! We want stop that expense to the motorist and offer you a completely free alternative to motor legal protection. Welcome to Free Motor Legal, the free membership site that is saving Britain £Millions.`,
  CTALabel: "TELL ME MORE",
  CTAUrl: "/",
  paragraphTitle: ``,
  paragraphContent: [
    {
      text: `What is motor legal protection? – Motor Legal Protection, also called legal expenses insurance, is usually sold as an add-on policy separate to your motor insurance policy. They are for assisting you in recovering any losses or expenses you may incur after an accident that was not your fault, such as lost earnings, taxi fares, hire car costs, treatment costs etc. They usually cost around £30 a year per vehicle. Now there is a free alternative which does exactly this. Welcome to Free Motor Legal.` 
    },
    {
      text: `Regardless of how many cars, vans or motorcycles you have, you can only drive one at a time, so by joining Free Motor Legal households with more than one vehicle enjoy multiple savings.`
    },
  ],
  paragraphConclusion: `We provide a free alternative to motor legal protection for car & van drivers as well as motorcyclists in England, Scotland & Wales`,
}


export const reasonList = {
  title: "So how does it work?",
  subtitle: "All for free!",
  reasons: [
    {
      reason: "You join up for free by clicking the “sign up” menu at the top of this page."
    },
    {
      reason: "We email you your membership documents (we are a paperless organisation)."
    },
    {
      reason: "If you are involved in an accident that was not your fault, you call our freephone number before calling your insurers."
    },
    {
      reason: "Legal assistance to get you compensation for financial losses and compensation without any fees or deductions."
    },
    {
      reason: "Our partners arrange to have your vehicle damage assessed and a like-for-like replacement vehicle while yours is fixed."
    },
  ]
}

export const howItWorks = {
  title: "",
  comparison: [
    {
      image: first,
      info: "You save £30 per vehicle you insure per year, every year for life",
    },
    {
      image: second,
      info: "As you are not claiming from your own insurer, you don’t affect your no claims bonus.",
    },
    {
      image: third,
      info: "You can avoid paying an insurance excess.",
    },

  ]
}


export const smallerBanner = {
  small: true,
  displayBtn: true,
  title: "SIGN UP ONCE. PEACE OF MIND FOREVER.",
  subtitle: '',
  label: "SIGN UP NOW",
  link: "true",
  url: "/sign-up-to-fml/",
}

export const firstBanner = {
  small: false,
  image: bannerFirstLogo,
  displayBtn: true,
  title: "As featured in",
  subtitle: '',
  label: "Read the full article here",
  link: "true",
  url: "https://www.moneymagpie.com/manage-your-money/how-to-stop-losing-money-with-the-motor-legal-protection-con",
  padding: false,
}

export const secondBanner = {
  small: false,
  image: bannerSecondLogo,
  displayBtn: true,
  title: "As featured in",
  subtitle: '',
  label: "Read the full article here",
  link: "true",
  url: "https://www.thisismoney.co.uk/money/cars/article-3365639/Save-30-year-car-insurance-New-website-offers-free-legal-service.html",
  padding: false,
}


export const aboutUsParallax = {
  banner: true,
  color: '#43b2fca6',
  alignment: 'center',
  image: aboutWebp,
  pngImage: aboutPng,
  title: `About Us`,
  paragraphTitle: `FreeMotorLegal was set up to save motorists money.`,
  paragraphContent: [
    {
      text: `Lee Jones has been in this industry for over 20 years, working for both insurers and law firms dealing with claims for people who had motor legal protection. The reality was that even though the clients had legal cover, the actual insurers of the legal protection policies would rarely pay any legal fees out to the solicitors doing the work. It was just a money maker for insurers and brokers earning big commission fees from the law firms that were appointed under the policy. This is why these policies always dictate that you must use the lawyer appointed by them.` 
    },
    {
      text: `He felt there had to be a better way of operating. So he did something about it.`
    },
    {
      text: `Free Motor Legal was established in 2012 to provide motorists in England, Scotland & Wales with a way of making sure they had all the necessary assistance after a non-fault collision, but without the need to charge them for an annual policy. And it’s working! Here’s what some of our members have said to us:`
    }
  ],
}

export const reviewList = {
  title: '',
  description: '',
  cardsPerLine: 4,
  reviewList: [
    {
      author: "Mr I MacKintosh",
      review: `“Prompt service, well handled, kept informed, stress free, very satisfied, thank you.”`
    },
    {
      author: "Mr A. Coleman",
      review: `“For a free service the attention to detail was as if I was paying £100’s of pounds. I would recommend you to all my friends.”`
    },
    {
      author: "Mr T. Von Trilsbach",
      review: `“Thorough, personal and attentive service at all times. Thank you for all your help and assistance with my claim.”`
    },
    {
      author: "Mr J. Lilley",
      review: `“Very good help at a very bad time. Thank you.”`
    },
    {
      author: "Mrs P. Ward",
      review: `“You rescued us after the accident and when our insurers made an upsetting time for us even worse by their incompetence, you came to our rescue again. I cannot thank you enough. I would urge anybody thinking of joining FML, just do it.”`
    },
    {
      author: "Mr L. Findell",
      review: `“Really great service actually. The advice given was spot on. I was initially worried the appointed solicitors from my insurance company were going to charge me for their time. But advice from the man on the phone at FreeMotorLegal advised to push on with your service and our newly appointed solicitors. Turned out to be great advice. The new solicitors were excellent. Good communication throughout, pushed the offending insurance company and solicitors for answers and got the incident resolved in a successful manner for my wife & I, very pleased. Thank you”`
    },
    {
      author: "Mr A. Khobnya",
      review: `“I am very grateful to Lee Jones from Free Motor Legal who took personal interest in my case and greatly assisted me in finding a company that dealt with my claim and damages to a full satisfaction. Thanks to Drive Car hire and Roy Powell for their professional and courteous service. Special thanks to Michelle Jones from Corries Solicitors who dealt with my claim.”`
    },
    {
      author: "Mr P. Mason",
      review: `“I had paid for legal cover, after 1 year of no help I signed up to FreeMotor Legal, where they helped and sorted the probloem out in a matter of weeks. Without FreeMotorLegal I would not have been able to claim on the other party’s insurance. I personally recommend from experience, no fees, quality help & advice right till the end.”`
    },
    {
      author: "Mrs L. Thomas",
      review: `“From the first call to FreeMotorLegal they dealt with my claim. They guided me through the process taking away all the stress and worry associated with the accident. Both FreeMotorLegal and the solicitors dealt with my claim in a professional way without any reminders from me. Thanks and well done.”`
    },
    {
      author: "Mr S. Cheshire",
      review: `“I was very pleased with the service provided by FreeMotorLegal and the Solicitor. Both were courteous and gave me a full explanation of what the procedures were. I received very good communication from the solicitor especially, who gave excellent customer service.”`
    },
    {
      author: "Mr J. Beeden",
      review: `“A supermarket trolley was pushed into my car by a Tesco employee and caused £500+ damage. You were able to get them to reimburse me. Thank you.”`
    },
    {
      author: "Mrs S. Knobnya",
      review: `“I am thankful to FreeMotorLegal for the advice they gave and for taking care of our case. Special thanks to Mr Jones for diligent communication and resolving our case.”`
    },
    {
      author: "Mrs L. Dickinson",
      review: `“Excellent service, fast, reliable. Highly recommended.”`
    },
    {
      author: "Ms E. Myers",
      review: `“The process wnet smoothly, excellent service from the solicitors.”`
    },
    {
      author: "Mrs M. Martin",
      review: `“Very pleased with the service, no hassles, everything went smoothly.”`
    },
    {
      author: "Mr D. Billing",
      review: `“Lee was very thorough and helpful answering my questions. Thanks!”`
    },
  ]
}

export const lastBanner = {
  small: false,
  displayBtn: true,
  title: "What are you waiting for?",
  subtitle: 'Sign up. It costs you nothing and will save you money and headaches should you be in a non-fault accident.',
  label: "SIGN UP NOW",
  link: "true",
  url: "/sign-up-to-fml/",
  bgColor: "#0bbcbf",
  padding: true,
}

